/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.bg-medmegreen { background-color: #51bf8f; }
.bg-medmeblue  { background-color: #6b94ec; }
.bg-medmegreen-light { background-color: #b8e3ce; }
.bg-medmeblue-light  { background-color: #c3d0f7; }
.bg-medmegreen-verylight { background-color: #e2f4eb; }
.bg-medmeblue-verylight  { background-color: #e8edfc; }
.bg-medme-sidebar {
  background-image: linear-gradient(#51bf8f, #6b94ec);
}
.bg-medmegreen-dark { background-color: #00a462; }
.bg-medmeblue-dark  { background-color: #0063df; }
.medmegreen { color: #000000; }
.medmeblue  { color: #6b94ec; }
.medmegreen-light { color: #b8e3ce; }
.medmeblue-light  { color: #c3d0f7; }
.medmegreen-dark { color: #00a462; }
.medmeblue-dark  { color: #0063df; }
.medmered-dark   { color: #950057; }
.no-outline { outline: 0 !important; }
.windowheight {
  height: calc(100vh - 66px);
}
.routeshady {
  -webkit-box-shadow: inset 0 1px 2px 0 rgba(60,64,67,.3), inset 0 2px 6px 2px rgba(60,64,67,.15);
  box-shadow: inset 0 1px 2px 0 rgba(60,64,67,.3), inset 0 2px 6px 2px rgba(60,64,67,.15);
}


.vw-25 { width: 25vw; }
.vw-50 { width: 50vw; }
.vw-75 { width: 75vw; }
.vw-100 { width: 100vw; }

.roboto {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.sticky {
  position: sticky;
}
.grid {
  display: grid;
}
.gcs1 { grid-column-start: 1 }
.gcs2 { grid-column-start: 2 }
.gcs3 { grid-column-start: 3 }
.gcs4 { grid-column-start: 4 }
.gcs5 { grid-column-start: 5 }
.gcs6 { grid-column-start: 6 }
.gcs7 { grid-column-start: 7 }
.gcs8 { grid-column-start: 8 }
.gce1 { grid-column-end: 1 }
.gce2 { grid-column-end: 2 }
.gce3 { grid-column-end: 3 }
.gce4 { grid-column-end: 4 }
.gce5 { grid-column-end: 5 }
.gce6 { grid-column-end: 6 }
.gce7 { grid-column-end: 7 }
.gce8 { grid-column-end: 8 }
.grs1 { grid-row-start: 1 }
.grs2 { grid-row-start: 2 }
.grs3 { grid-row-start: 3 }
.grs4 { grid-row-start: 4 }
.grs5 { grid-row-start: 5 }
.grs6 { grid-row-start: 6 }
.grs7 { grid-row-start: 7 }
.grs8 { grid-row-start: 8 }
.gre1 { grid-row-end: 1 }
.gre2 { grid-row-end: 2 }
.gre3 { grid-row-end: 3 }
.gre4 { grid-row-end: 4 }
.gre5 { grid-row-end: 5 }
.gre6 { grid-row-end: 6 }
.gre7 { grid-row-end: 7 }
.gre8 { grid-row-end: 8 }

.gafc { grid-auto-flow: column }
.gafr { grid-auto-flow: row }

.gtr-header {
  grid-template-rows: 66px 1fr;
}
.gtc-sidebar {
  grid-template-columns: 180px 1fr;
}
.user-data-grid {
  grid-template-columns: 180px 1fr;
}
.stats-grid {
  grid-template-columns: 20% 20% 20% 20% 20%;
}

.stats-top-box {
  grid-template-columns: 1fr 100px;
}

.js-right { justify-self: end }
.jc-start { justify-content: start; }

.banner-grid {
  grid-template-columns: 30px 90px 1fr 200px;
}
.user-grid {
  grid-template-columns: 30px 150px 1fr 250px 30px;
}
.swagger-ui .info {
  margin-top: 0 !important;
}

.toasty {
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.toasty.show {
  opacity: 0.8;
}

.VictoryContainer svg {
  overflow: visible !important;
}

.VictoryContainer svg g {
  overflow: visible !important;
}

.word-break-all {
  word-break: break-all;
}

.pre-wrap {
  white-space: pre-wrap;
}
